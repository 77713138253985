// FONT

@import url('https://use.typekit.net/snw1qyv.css');

// Neumeier <- kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

body {
  background-color: $nmwhite;
  font-family: $nmfont;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAVIGATION

.navbar-light {
  background-color: rgba($color: $nmblack, $alpha: 0.9) !important;
  color: $white;
}
#header {
  box-shadow: $box-shadow-xl;
  height: $calculated-navbar-height-sm;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
  }

  .navbar {
    padding: 0.5rem;
    font-size: $h5-font-size;

    .navbar-brand {
      padding: 0;

      img {
        height: 2rem;
        max-height: 2rem;
        @include media-breakpoint-up(sm) {
          height: 3rem;
          max-height: 3rem;
        }
        @include media-breakpoint-up(md) {
          height: 4rem;
          max-height: 4rem;
        }
        &:hover,
        &:focus {
          filter: opacity(0.66);
        }
      }
    }
  }
}

.navbar {
  .hamburger {
    color: $nmblack !important;
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 1px;
    }
    &.is-active {
      .hamburger-inner,
      .hamburger-inner:after,
      .hamburger-inner:before {
        background-color: $nmblack !important;
      }
    }
  }
  .secondary-menu,
  .main-menu {
    display: flex;
    align-items: center;
    transition: all 0.2s;
  }
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.navbar .nav-link .badge.expiration {
  display: none;
  @media only screen and (min-width: 350px) {
    display: inherit;
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: $nmblack, $alpha: 0.9) !important;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
    a {
      color: $nmwhite;
    }
    a.active,
    a:hover {
      color: $gray-300;
    }
  }
}

.offcanvas-collapse {
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background: rgba($color: $gray-100, $alpha: 0.9) !important;
  backdrop-filter: blur(5px);
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

.shoppingcart-finisher,   // bottom border mit 'direkt zur Kasse' Button
.sticky-bottom.is-valid {
  // aber auch der sticky beim kaufabschluss
  background: rgba($color: $nmblack, $alpha: 0.8) !important;
  backdrop-filter: blur(5px);
}

// MAKI

.maki {
  @extend .p-1;
  .card-body {
    flex-direction: column-reverse;
    background-color: $white;
    transition: 0.5s ease-out;
  }
  .card-title {
    text-align: left;
    padding-left: 10px;
    color: $black;
    .title {
      text-transform: uppercase;
      font-size: $h4-font-size;
      font-weight: 900 !important;
    }
    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
  }
  &:hover {
    .card-body {
      background-color: $nmwhite;
      .card-title {
        color: $nmred;
      }
    }
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;

  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $nmgrey;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .sold-out-status_sold_out {
      z-index: 2;
      transform: rotate(2.5deg);
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h3-font-size;
      color: $nmred;
      font-weight: 700;
    }

    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      p {
        margin-bottom: 0px;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        padding-left: 0;
        line-height: 2rem;
      }

      .title {
        font-size: 0.6em;
        margin-bottom: 0;
        color: $nmblack;
      }

      .location {
        font-size: $h3-font-size !important;
        font-weight: 700;
        color: $nmblack;
      }

      .nigiri-tags {
        display: block !important;
        font-size: 1rem;
        text-transform: none;
        .tag {
          margin-right: 5px;
        }
      }
    }

    .nigiri-cta .btn {
      color: $nmblack;
      background: transparent;
      border: 2px solid $nmblack;
      &:hover {
        background-color: $nmred;
        color: $nmwhite;
        border: 2px solid $nmred;
      }
    }
  }
  // .subtitle {
  //   font-size: 0.5em;
  //   margin-bottom: 0;
  //   color: $gray-500;
  //   font-weight: normal;
  //   @include media-breakpoint-down(md) {
  //     line-height: 1rem;
  //     margin-bottom: 0.5rem;
  //   }
  // }
}

// Ticket Detail Page

.ticket-detail-page {
  .card {
    border: none;
    border-radius: 0px;
  }
  .card.article-title {
    background-color: $nmblack;
    text-transform: uppercase;
    .super-title {
      color: $gray-200;
    }
    h1 {
      color: $nmwhite;
      font-size: $h2-font-size;
      font-weight: 700 !important;
      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
      }
      font-weight: 500;
      small {
        color: $nmwhite;
      }
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $nmblack;
    text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $nmgrey;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $nmgrey;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// HEADER

// paralax header on TL and PL
.paralax-header {
  min-height: calc(90vh - #{$calculated-navbar-height-sm});
  @include media-breakpoint-up(md) {
    min-height: calc(90vh - #{$calculated-navbar-height});
  }
  background-image: url('./assets/images/header.jpg');
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    font-size: $h1-font-size;
    @include media-breakpoint-up(md) {
      font-size: $h1-font-size * 2;
    }
    color: white !important;
    transform: translateY(33vh);

    &:hover {
      // color: $nmred !important;
      text-decoration: underline;
      text-decoration-color: $nmred;
      text-decoration-thickness: 0.16em;
    }
  }
}

// neue Headings
body.ticket-listing-page,
body.product-listing-page {
  .heading {
    display: flex;
    align-items: start;
    background: url('./assets/images/nmbg.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: $nmred;
    height: 40vh;

    h1 {
      font-size: 4.5rem;
      // text-transform: uppercase;
      @include media-breakpoint-up(md) {
        margin-left: 19vw;
      }
    }
  }
}

//FILTER BUTTONS OVER TICKET LISTING

.container.listing-filter-buttons-container {
  // padding-left: 0;
  // padding-right: 0;
}

.listing-filter-buttons-container {
  h5 {
    text-align: center;
    margin-top: 1.5rem;
    color: $nmred;
  }
}
.listing-buttons-container {
  .btn {
    // border-radius: 0;
    font-size: $h5-font-size;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active,
    &:focus {
      background-color: $nmred !important;
      color: $white !important;
    }
  }
}

// HIDE TAGS USED JUST FOR FILTERING THE LISTING

.tag-7919,
.tag-7918,
.tag-7917,
.tag-7916 {
  display: none;
}

// FOOTER

#footer {
  min-height: 30vh;
  background-color: $nmblack;
  color: #f8f8f8;
  a {
    color: #f8f8f8;
  }
  border-bottom: 4px solid $primary;
}

// CHECKOUT

.checkout .sticky-bottom {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}

// OTHER

#product-listing {
  padding-top: 2rem;
}
.hero-slider {
  margin-bottom: 0;
}

.stage-wrapper {
  box-shadow: $box-shadow-xl;
  background-color: #fbccb0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.stage {
  box-shadow: $box-shadow-xl;
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}
